import { Component, OnInit } from '@angular/core';
import { ActivatedRoute,NavigationEnd, Router } from '@angular/router';
import { user_socket } from '../app.module';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import * as moment from 'moment';
import { UsersService } from '../services/users.service';
import { filter } from 'rxjs/operators';
@Component({
  selector: 'app-gamelist',
  templateUrl: './gamelist.component.html',
  styleUrls: ['./gamelist.component.scss']
})
export class GamelistComponent implements OnInit {
  userDetails: any;
  cricketData: any;
  soccerData: any;
  activeTab:any;
  tennisData: any;
  eventTypeId:any;
  moment: any = moment;
  virtualCricketData: any;
  type: string;
  dataList:any;
  countryList:any;
  constructor(public route: ActivatedRoute, public socket: user_socket, public ngxLoader: NgxUiLoaderService, public usersService: UsersService, public router: Router) {
   
    this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe(() => {
        // Fetch the 'type' parameter from the current route
        this.type = this.route.snapshot.paramMap.get('type');
      
        if (this.type) {
          this.homeSoc();
          
        }
      });
    this.userDetails = JSON.parse(sessionStorage.getItem('userDetails'));
  }

  ngOnInit(): void {
   
  }
 
  homeSoc() {
   
    let data = {
      token: this.userDetails.verifytoken
    };

    this.socket.emit('get-home-markets', data);
    this.ngxLoader.start();

    this.socket.on('get-homemarkets-success', (function (data: any) {

      if (data) {
        this.ngxLoader.stop();
        // console.warn(data);
        this.cricketData = data[0];
        this.soccerData = data[1];
        this.tennisData = data[2];
        // this.dataLength=((data[0].length) || (data[1].length) || (data[2].length));
        this.getUserBalance();
      }

    }).bind(this));

    if(this.type=='greyhoundrace' || this.type=='horserace'){
     let value="7"
     this.eventTypeId="7"
     this.countryList=[];
     this.dataList=[]
      if(this.type=='greyhoundrace'){
           value="4339"
            this.eventTypeId="4339"
      }

      const data = {
        token: this.userDetails.verifytoken,
        eventTypeId:value
      };
      this.dataList=[];
      
      this.socket.emit("get-others-sport-markets", data);
    }
    this.socket.on(
      "get-othermarkets-success",
      function (data: any) {
       
        if (data) {
          this.ngxLoader.stop();
          this.countryList=data.countryList;
              this.activeTab=this.countryList[0]
              this.dataList=data.data.filter((val)=>val.countryCode==this.activeTab)

        }
      }.bind(this)
    );

  }

  getUserBalance() {

    this.usersService.Post("getUserDetails", null).subscribe((res: any) => {

      if (res.success) {
        this.usersService.updateUserBalanceSubject(res.doc);
      }
      else {
        console.warn(res.message);
      }
    });

  }

  otherMarkets(value,type){
   
    this.dataList=[]
    const data = {
      token: this.userDetails.verifytoken,
      eventTypeId:value
    };
    
    this.socket.emit("get-others-sport-markets", data);
    this.socket.on(
      "get-othermarkets-success",
      function (data: any) {
        console.log(data)
        if (data) {
          this.ngxLoader.stop();
          this.countryList=data.countryList;
          if(this.countryList.length)
          {
            if(!this.activeTab){
              this.activeTab=this.countryList[0]
              this.dataList=data.data.filter((val)=>val.countryCode==this.activeTab)
            }
            else{
              this.activeTab=type
              this.dataList=data.data.filter((val)=>val.countryCode==this.activeTab)
            }
            
          }
         

        }
      }.bind(this)
    );
  }

  matchDetail(eventId) {
    sessionStorage.setItem('eventId', eventId)
    this.router.navigate(['match-detail']);
  }

}
