<div class="desktopmain-main">
  <div class="header-bottom col-md-12">
    <nav class="navbar navbar-expand-md btco-hover-menu">
      <button type="button" data-toggle="collapse" data-target="#navbarNavDropdown" aria-controls="navbarNavDropdown"
        aria-expanded="false" aria-label="Toggle navigation" class="navbar-toggler">
        <span class="navbar-toggler-icon"></span>
      </button>
      <!--desktop_header  -->
      <div class="collapse navbar-collapse">
        <ul class="list-unstyled navbar-nav">
          <li class="nav-item" [routerLink]="['/home']" routerLinkActive="active">
            <a class="router-link-exact-active router-link-active"><b>Home</b></a>
          </li>
          <li *ngIf="cricket_menu" class="nav-item" [routerLink]="['/gamelist/cricket']" routerLinkActive="active">
            <a href="javascript:void(0)" class=""><b>Cricket</b></a>
          </li>
          <!-- <li class="nav-item" routerLinkActive="active">
            <a href="javascript:void(0)" class=""
              ><b class="new-launch-text">Election 2022</b></a
            >
          </li>
          <li class="nav-item" routerLinkActive="active">
            <a href="javascript:void(0)" class=""
              ><b class="new-launch-text">Fifa Cup - 22</b></a
            >
          </li> -->
          <li *ngIf="tennis_menu" class="nav-item" routerLinkActive="active" [routerLink]="['/gamelist/tennis']">
            <a href="javascript:void(0)" class=""><b>Tennis</b></a>
          </li>
          <li *ngIf="soccer_menu" class="nav-item" routerLinkActive="active" [routerLink]="['/gamelist/football']">
            <a href="javascript:void(0)" class=""><b>Football</b></a>
          </li>
          <!-- <li class="nav-item" routerLinkActive="active">
            <a href="javascript:void(0)" class=""><b>Table Tennis</b></a>
          </li>
          <li class="nav-item" routerLinkActive="active">
            <a href="javascript:void(0)" class=""><b>Kabaddi</b></a>
          </li>
          <li class="nav-item" routerLinkActive="active">
            <a href="javascript:void(0)" class=""><b>Basketball</b></a>
          </li>
          <li class="nav-item" routerLinkActive="active">
            <a href="javascript:void(0)" class=""><b>Volleyball</b></a>
          </li> -->
          <ng-container *ngIf="live_casino_menu">
            <li class="nav-item" routerLinkActive="active" routerLink="/casino-url/920381/AC">
              <a href="javascript:void(0)" class=""><span><b>Baccarat</b></span></a>
            </li>
            <li class="nav-item" routerLinkActive="active" routerLink="/casino-url/569672/AC">
              <a href="javascript:void(0)" class=""><span><b>32 Cards</b></span></a>
            </li>
            <li class="nav-item" routerLinkActive="active" routerLink="/casino-url/567681/AC">
              <a href="javascript:void(0)" class=""><span><b>Teenpatti</b> </span></a>
            </li>
            <li class="nav-item" routerLinkActive="active" routerLink="/casino-url/675642/AC" >
              <a href="javascript:void(0)" class=""><span><b>Poker</b></span></a>
            </li>
            <li class="nav-item" routerLinkActive="active" routerLink="/casino-url/987911/AC">
              <a href="javascript:void(0)" class=""><span><b>Amar Akbar Anthony</b></span></a>
            </li>
          </ng-container>
          <li class="nav-item" routerLinkActive="active" routerLinkActive="active" [routerLink]="['/gamelist/horserace']">
            <a href="javascript:void(0)" class=""><span><b>HORSE RACING</b></span></a>
          </li>
          <li class="nav-item" routerLinkActive="active" routerLinkActive="active" [routerLink]="['/gamelist/greyhoundrace']">
            <a href="javascript:void(0)" class=""><span><b>GREYHOUND RACING</b></span></a>
          </li>
          <li class="nav-item" routerLinkActive="active" style="display: none;">
            <a href="javascript:void(0)" class=""><span><b>KABADDI</b></span></a>
          </li>
          <li class="nav-item" routerLinkActive="active" style="display: none;">
            <a href="javascript:void(0)" class=""><span><b>POLITICS</b></span></a>
          </li>
          <li *ngIf="casino_menu" class="nav-item" routerLink="/casino-url/98790/AC" routerLinkActive="active">
            <a href="javascript:void(0)" class=""><span><b>CASINO</b></span></a>
          </li>
          <li class="nav-item" routerLinkActive="active" style="display: none;">
            <a href="javascript:void(0)" class=""><span><b>SPORTS BOOK</b><em class="newbadge">New</em></span></a>
          </li>

          <li *ngIf="casino_menu" class="nav-item" [routerLink]="['/slotgame']" routerLinkActive="active">
            <a href="javascript:void(0)" class=""><span><b>INT CASINO</b></span></a>
          </li>
          <li class="nav-item" routerLinkActive="active" style="display: none;">
            <a href="javascript:void(0)" class=""><span><b>BINARY</b><em class="newbadge">New</em></span></a>
          </li>
        </ul>
      </div>
    </nav>
  </div>
</div>