<app-header></app-header>
<div class="desktopmain-main">
    <!-- desktop_header -->
    <app-desk-header></app-desk-header>
    <!-- desktop_header_end -->
    <div class="container-fluid container-fluid-5">
       <div class="row row5">
        <!--desktop_sidebar_others  -->
        <div class="sidebar col-md-2">
          <app-desk-sidebar></app-desk-sidebar>
           </div>
          <!--flterTabsGrey_dashboard--> 
          <div class="col-md-10 featured-box">
             <div>
                <!--flterTabsandMatchDetails--> 
                <div class="pb-4">
  
                  <div class="d-flex" style="background-color: var(--theme2-bg); color: #fff;"> <span class="ml-2 ptitle" style="padding: 6px;
                     font-weight: 500;">LIVE CASINO</span>
                  </div>

                  <ul  role="tablist" style="display: none;" id="home-events" class="nav nav-tabs">
                    <li class="nav-item" *ngFor="let sport of sportList" (click)="casino_games(sport.code);"><a href="javascript:void(0)" data-toggle="tab" class="nav-link active" [ngClass]="{'active': casino_type === sport.code}">{{sport.name}}</a></li>

                 </ul>
                  
        

          <!-- slotgame -->
              <ng-container *ngIf="rout==='/slotgame'">
               <div class="container-fluid mt-1">
               <div class="row">
                <div class="card col-1" *ngFor="let x of casino_data" routerLink="/casino-url/{{x.code}}/{{x.providerCode}}">
                  <img [src]="x.thumb" class="card-img-top" alt="..." >
                  <!-- <div class="button"><a>Pragmatic Play</a></div> -->
                </div>
               </div>
            </div>
              </ng-container>
                               
                </div>
  
              
             </div>
          </div>
       </div>
    </div>

      <!-- deshboard_footer -->
       <app-desk-footer></app-desk-footer>
  </div>
