import {
  AfterViewInit,
  Component,
  OnDestroy,
  OnInit,
  TemplateRef,
  ViewChild,
} from "@angular/core";
import { HeaderComponent } from "../header/header.component";
import { FooterComponent } from "../footer/footer.component";
import { Router, ActivatedRoute } from "@angular/router";
import * as moment from "moment";
import { ToastrService } from "ngx-toastr";
import { user_socket } from "../app.module";
import { UsersService } from "../services/users.service";
import { NgxUiLoaderService } from "ngx-ui-loader";
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";

// import { environment } from 'src/environments/environment';
// import { UserIdleService } from 'angular-user-idle';

@Component({
  selector: "app-dashboard",
  templateUrl: "./dashboard.component.html",
  styleUrls: ["./dashboard.component.scss"],
  providers: [HeaderComponent, FooterComponent],
})
export class DashboardComponent implements OnInit, AfterViewInit, OnDestroy {

  @ViewChild("dashModal1") templateRef: TemplateRef<any>;

  homeData: any;
  modal_text: string;
  // dataLength:number;
  activeTab:any;
  cricketData: any;
  soccerData: any;
  tennisData: any;
  countryList:any;
  eventTypeId:any
  var_others:boolean = false;
  virtualCricketData: any;
  ballbyballData: any;
  homeInplayData: any;
  dataList:any;
  // virtualCricketDataArr:any;
  moment: any = moment;
  userDetails: any;
  var_football: boolean = false;
  var_tennis: boolean = false;
  var_cricket: boolean = false;
  var_homeInplay: boolean = false;
  casinoFooter: boolean;
  currentDate = new Date().toISOString();
  deviceInfo: any;
  casinoStatus: boolean = true;
  casino_type: string = 'OUR CASINO';
  casino_data: any = [];
  // menu_option
  cricket_menu: boolean;
  tennis_menu: boolean;
  soccer_menu: boolean;
  casino_menu: boolean;
  live_casino_menu: boolean;
  virtual_cricket_menu: boolean;
  ballbyball_menu: boolean;
  page_type: any;
  colorValue: any;
  timeInt: any;
  timeIntInplay: any;
  timeIntHomeInplay: any;
  modalRef: BsModalRef;
  live_casino_tab: boolean;
  slot_game_tab: boolean;
  our_casino_tab: boolean;

  // loader:boolean=false;
  constructor(
    public modalService: BsModalService,
    public route: ActivatedRoute,
    public router: Router,
    public toastr: ToastrService,
    public socket: user_socket,
    public usersService: UsersService,
    public ngxLoader: NgxUiLoaderService
  ) {
    this.page_type = sessionStorage.getItem("page_type");
    if (this.page_type === "paisaexch") {
      this.colorValue = "#1b1b1b";
    }
  
    document.documentElement.style.setProperty("--bg-color", this.colorValue);
    this.route.params.subscribe((params) => {
      this.router.routeReuseStrategy.shouldReuseRoute = () => false;
      this.userDetails = JSON.parse(sessionStorage.getItem("userDetails"));
      // checkDevice
      this.deviceInfo = JSON.parse(sessionStorage.getItem("is_desktop"));
      if (params.sportName === undefined) {
        if (sessionStorage.getItem("loginStatus") === "true") {
          // if (this.deviceInfo) {
          //   this.home_inplay_fun();
          // }
          // else {
          //   this.homeSoc();
          // }
          if (!this.deviceInfo) {
            this.home_inplay_fun();
            this.homeSoc();
          }

        } else {
          this.homeFreeSoc();
        }
        this.casinoFooter = false;
      } else if (
        params.sportName === "inplay" &&
        sessionStorage.getItem("loginStatus") === "true"
      ) {
        this.Inplay();
        this.casinoFooter = true;
      } else if (
        params.sportName === "casino" &&
        sessionStorage.getItem("loginStatus") === "true"
      ) {
        setTimeout(() => {
          this.ngxLoader.stop();
        }, 600);
        this.casinoFooter = true;
        this.casinoStatus = false;
        this.our_casino_tab = true;
      }
      else if (
        params.sportName === "live_casino" &&
        sessionStorage.getItem("loginStatus") === "true"
      ) {
        setTimeout(() => {
          this.ngxLoader.stop();
        }, 600);
        this.casinoFooter = true;
        this.casinoStatus = false;
        this.live_casino_tab = true;
        this.casino_type = 'LIVECASINO';
        this.casino_games("LIVECASINO");
      }
      else if (
        params.sportName === "slot_game" &&
        sessionStorage.getItem("loginStatus") === "true"
      ) {
        setTimeout(() => {
          this.ngxLoader.stop();
        }, 600);
        this.casinoFooter = true;
        this.casinoStatus = false;
        this.slot_game_tab = true;
        this.casino_type = 'SLOT';
        this.casino_games("SLOT");
      }

    });
  }

  async getDetials() {
    try {
      const data = await JSON.parse(sessionStorage.getItem("userDetails"));
      return data;
    } catch (e) {
      return null;
    }
  }

  home() {
    this.router.navigate(["home"]);
  }

  ngOnInit(): void {
    this.auraGaming()
    this.usersService.getSportEvents().subscribe((data) => {
      //message contains the data sent from service
      this.menu_hide_show();
     
      // this.betSub.unsubscribe();
    });
    // this.loader=true;
    this.menu_hide_show();
    this.getUserBalance();
  }

  trackByFunction(index, item) {
    return item.id;
  }

  ngAfterViewInit() {
    if (
      sessionStorage.getItem("dashboard_alert") === "true" &&
      this.page_type === "diamond"
    ) {
      this.changeIcon();
      this.modelOpen();
      setTimeout(() => {
        sessionStorage.setItem("dashboard_alert", "false");
      }, 3000);
    }
  }

  modelOpen() {
    this.modalRef = this.modalService.show(this.templateRef, {});
  }

  async findHostName() {
    return window.location.hostname;
  }

  async changeIcon() {
    const Hostname = await this.findHostName();
    const splithostname = Hostname.split(".");
    this.modal_text = splithostname[0];
  }

  menu_hide_show() {
    const data = {
      details: {
        username: this.userDetails.details.username,
        _id: this.userDetails._id,
        key: this.userDetails.key,
        role: this.userDetails.details.role,
        token: this.userDetails.verifytoken,
      },
      targetUser: {
        username: this.userDetails.details.username,
        role: this.userDetails.details.role,
        status: this.userDetails.details.status,
      },
    };

    this.usersService.Post("getUserEvenets", data).subscribe((res: any) => {

      if (res.error) {
        this.toastr.error(res.message, "", {
          timeOut: 10000,
        });
      } else {
        this.cricket_menu = res.response.availableEventTypes.includes("4");
        this.soccer_menu = res.response.availableEventTypes.includes("1");
        this.tennis_menu = res.response.availableEventTypes.includes("2");
        this.casino_menu = res.response.availableEventTypes.includes("c9");
        this.live_casino_menu = res.response.availableEventTypes.includes("c1");
        this.virtual_cricket_menu = res.response.availableEventTypes.includes("v9");
        this.ballbyball_menu = res.response.availableEventTypes.includes("b9");
        if (this.cricket_menu) {
          if (this.deviceInfo) {
            this.home_inplay_fun();
          }
          else {
            this.cricket_fun();
          }

        } else if (this.soccer_menu) {
          if (this.deviceInfo) {
            this.home_inplay_fun();
          }
          else {
            this.football_fun();
          }


        } else if (this.tennis_menu) {
          if (this.deviceInfo) {
            this.home_inplay_fun();
          }
          else {
            this.tennis_fun();
          }

        }
        if (!this.casino_menu && this.live_casino_menu) {
          this.casino_type = "LIVECASINO";
          this.casino_games("LIVECASINO");
        }
        // if (this.live_casino_menu) {
        //   this.casino_type = "OUR CASINO";
        // }
        // else if (this.casino_menu) {
        //   this.casino_type = "LIVECASINO";
        // }
      }
    });
  }

  homeInplay() {
    const data = {
      token: this.userDetails.verifytoken,
    };
    this.socket.emit("get-inplay-markets", data);
    this.timeIntHomeInplay = setInterval(() => {
      this.socket.emit("get-inplay-markets", data);
    }, 3000)
    this.ngxLoader.start();
    if (this.deviceInfo) {
      this.socket.on(
        "get-inplaymarkets-success",
        function (data: any) {
          // console.warn(data);

          // this.dataLength=((data[0].length) || (data[1].length) || (data[2].length ||data[3]));
          if (data) {
            this.ngxLoader.stop();
            let cricketdt: any = [];
            let soccerdt: any = [];
            let tennisdt: any = [];
            let virtualdt: any = [];
            let ballbyballdt: any = [];
            if (this.cricket_menu) {
              cricketdt = data[0]
            }
            if (this.soccer_menu) {
              soccerdt = data[1]
            }
            if (this.tennis_menu) {
              tennisdt = data[2]
            }
            if (this.virtual_cricket_menu) {
              virtualdt = data[3]
            }
            if (this.ballbyball_menu) {
              ballbyballdt = data[5]
            }
            // this.ballbyballData = data[5];
            this.homeInplayData = [].concat(virtualdt, ballbyballdt, cricketdt, soccerdt, tennisdt);
          }
        }.bind(this)
      );
    }

  }

  Inplay() {
    const data = {
      token: this.userDetails.verifytoken,
    };

    this.socket.emit("get-inplay-markets", data);
    this.timeIntInplay = setInterval(() => {
      this.socket.emit("get-inplay-markets", data);

    }, 3000)
    this.ngxLoader.start();
    if (!this.deviceInfo) {
      this.socket.on(
        "get-inplaymarkets-success",
        function (data: any) {
          // this.dataLength=((data[0].length) || (data[1].length) || (data[2].length ||data[3]));
          if (data) {
            this.ngxLoader.stop();
            this.cricketData = data[0];
            this.soccerData = data[1];
            this.tennisData = data[2];
            this.ballbyballData = data[5];
            if (this.virtual_cricket_menu) {
              this.virtualCricketData = data[3];
            }
            else {
              this.virtualCricketData = [];
            }

          }
        }.bind(this)
      );

     
    }
   
  }

  homeSoc() {
    let data = {
      token: this.userDetails.verifytoken,
    };

    this.socket.emit("get-home-markets", data);
    this.timeInt = setInterval(() => {
      this.socket.emit("get-home-markets", data);
    }, 3000)
    this.ngxLoader.start();

    this.socket.on(
      "get-homemarkets-success",
      function (data: any) {
        if (data) {
          // console.log(data)
          this.ngxLoader.stop();
          // console.warn(data);
          this.cricketData = data[0];
          this.soccerData = data[1];
          this.tennisData = data[2];
          // this.dataLength=((data[0].length) || (data[1].length) || (data[2].length));
        }
      }.bind(this)
    );
  }

  homeFreeSoc() {
    let data = {
      filter: {
        managers: "OSGCLUB",
        eventTypeId: { $nin: ["t9", "4321"] },
        visible: true,
        deleted: false,
        marketType: { $in: ["MATCH_ODDS", "TOURNAMENT_WINNER"] },
        "marketBook.status": { $ne: "CLOSED" },
      },
      sort: { openDate: 1 },
    };

    this.socket.emit("get-free-home-markets", data);

    this.socket.on(
      "get-freehomemarkets-success",
      function (data: any) {
        // this.dataLength=data.length;
        if (data) {
          this.cricketData = data[0];
          this.soccerData = data[1];
          this.tennisData = data[2];
          // this.dataLength=((data[0].length) || (data[1].length) || (data[2].length));
        }
      }.bind(this)
    );
  }

  auraGaming()
  {
   
      const data = {
        providerCode:'AC'
      };
      
      this.usersService.Post("casino/games",data).subscribe((res:any)=>{ 
        if(res.status)
        {
          this.casino_data=[];
          this.casino_data=res.data;
         
        }
        else{
          this.casino_data=[];
          //this.toastr.error(res.message, 'Error!');
        }
       });

        
  }

  getUserBalance() {
    this.usersService.Post("getUserDetails", null).subscribe((res: any) => {
      if (res.success) {
        this.usersService.updateUserBalanceSubject(res.doc);
      } else {
        console.warn(res.message);
      }
    });
  }

  refresh_bal() {
    const data = {
      user: {
        _id: this.userDetails._id,
        key: this.userDetails.key,
        token: this.userDetails.verifytoken,
        details: {
          username: this.userDetails.details.username,
          role: this.userDetails.details.role,
          status: this.userDetails.details.status,
        },
      }
    };

    this.socket.emit('refresh-balance', data);
  }

  matchDetail(eventId, eventTypeId) {
    if (eventTypeId === 'v9') {
      this.virtualDetial(eventId);
    }
    else if (eventTypeId === 'b9') {
      this.ballbyballDetail(eventId);
    }

    else {
      this.refresh_bal();
      sessionStorage.setItem('eventId', eventId);
      this.router.navigate(["match-detail"]);
    }
  }

  ballbyballDetail(eventId) {
    this.refresh_bal();
    sessionStorage.setItem('eventId', eventId);
    this.router.navigate(["ballbyball-detail"]);
  }

  virtualDetial(eventId) {
    this.refresh_bal();
    this.router.navigate(["virtual-detail", eventId]);
  }

  no_match() {
    this.var_football = false;
    this.var_cricket = false;
    this.var_tennis = false;
    this.var_others=false
    this.var_homeInplay = false;
  }

  football_fun() {
    if (this.deviceInfo) {
      clearInterval(this.timeIntHomeInplay);
      clearInterval(this.timeInt);
      this.homeSoc();
    }
    this.var_football = true;
    this.var_cricket = false;
    this.var_others=false;
    this.var_tennis = false;
    this.var_homeInplay = false;

  }
  tennis_fun() {
    if (this.deviceInfo) {
      clearInterval(this.timeIntHomeInplay);
      clearInterval(this.timeInt);
      this.homeSoc();
    }
    this.var_tennis = true;
    this.var_football = false;
    this.var_cricket = false;
    this.var_others=false;
    this.var_homeInplay = false;
  }
  cricket_fun() {
    if (this.deviceInfo) {
      clearInterval(this.timeIntHomeInplay);
      clearInterval(this.timeInt);
      this.homeSoc();
    }
    this.var_cricket = true;
    this.var_football = false;
    this.var_tennis = false;
    this.var_others=false;
    this.var_homeInplay = false;
  }

  home_inplay_fun() {
    if (this.deviceInfo) {
      clearInterval(this.timeInt);
      clearInterval(this.timeIntHomeInplay);
      this.homeInplay();
    }
    this.var_homeInplay = true;
    this.var_cricket = false;
    this.var_football = false;
    this.var_tennis = false;
  }

  goToInplay(sportName) {
    this.router.navigate(["home/" + sportName]);
  }

  casino_games(type: string) {
    this.casino_type = type;
    if (type === "OUR CASINO") {
      this.casino_data = [];
      this.router.navigate(["/casino-url/98790/AC"]);
     
    } else {
      const data = {
        gametype: type,
      };

      this.router.navigate(["/casino-url/700058/AC"]);
    }
  }

  openCasino(gameID: string, tableID: string) {
    if (this.userDetails.details.betStatus) {
      const data = { gameId: gameID, tableId: tableID };
      sessionStorage.setItem("casinoDb", JSON.stringify(data));
      this.router.navigate(["./casino-url"]);
    } else {
      this.toastr.error("Error in placing bet.Bet Disable pls Contact Upline.");
    }
  }

  otherMarkets(value,type){
    this.var_others=true;
    this.var_cricket = false;
    this.var_homeInplay = false;
    this.var_football = false;
    this.var_tennis = false;
    this.dataList=[]
    const data = {
      token: this.userDetails.verifytoken,
      eventTypeId:value
    };
    this.eventTypeId=value
    this.socket.emit("get-others-sport-markets", data);
    this.socket.on(
      "get-othermarkets-success",
      function (data: any) {
        
        if (data) {
          this.ngxLoader.stop();
          this.countryList=data.countryList;
          if(this.countryList.length)
          {
            if(!this.activeTab){
              this.activeTab=this.countryList[0]
              this.dataList=data.data.filter((val)=>val.countryCode==this.activeTab)
            }
            else{
              this.activeTab=type
              this.dataList=data.data.filter((val)=>val.countryCode==this.activeTab)
            }
            
          }
         

        }
      }.bind(this)
    );
  }

  openCasinoDetail(eventId) {
    // this.router.navigate(["./casino-detail", eventId]);
  }

  ngOnDestroy() {
    this.socket.removeAllListeners("");
    clearInterval(this.timeInt);
    clearInterval(this.timeIntInplay);
  }
}
