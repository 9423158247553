<app-header></app-header>
<div class="desktopmain-main">
    <!-- desktop_header -->
    <app-desk-header></app-desk-header>
    <!-- desktop_header_end -->
    <div class="container-fluid container-fluid-5">
       <div class="row row5">
        <!--desktop_sidebar_others  -->
        <div class="sidebar col-md-2">
          <app-desk-sidebar></app-desk-sidebar>
           </div>
         
          <!--flterTabsGrey_dashboard--> 
          <div class="col-md-10 featured-box">
             <div>
                <!--flterTabsandMatchDetails--> 
                <div class="pb-4">
  
                   <ul role="tablist" id="home-events" class="nav nav-tabs">
                      <li *ngIf="type==='football'" class="nav-item"><a href="javascript:void(0)" data-toggle="tab" class="nav-link active">Football</a></li>
                      <li *ngIf="type==='tennis'" class="nav-item"><a href="javascript:void(0)" data-toggle="tab" class="nav-link active">Tennis</a></li>
                      <li *ngIf="type==='cricket'" class="nav-item"><a href="javascript:void(0)" data-toggle="tab" class="nav-link active">Cricket</a></li>
                      <li *ngIf="type==='horserace'" class="nav-item"><a href="javascript:void(0)" data-toggle="tab" class="nav-link active">Horse Race</a></li>
                      <li *ngIf="type==='greyhoundrace'" class="nav-item"><a href="javascript:void(0)" data-toggle="tab" class="nav-link active">Grey Hound Race</a></li>
                   </ul>
                   <div class="tab-content">
                      <div class="tab-pane active">
                         <div class="coupon-card coupon-card-first">
                            <div class="card-content">
                               <table class="table coupon-table">
                                  <thead  *ngIf="type!='horserace' && type!='greyhoundrace'">
                                     <tr>
                                        <th style="width: 63%;"> Game</th>
                                        <th colspan="2"> 1 </th>
                                        <th colspan="2"> X </th>
                                        <th colspan="2"> 2 </th>
                                     </tr>
                                  </thead>
                                  <tbody>
  
                                    <!-- Soccer -->
                                      <ng-container *ngIf="type==='football'" >
                                        <ng-container *ngIf="soccerData?.length">
  
                                      <tr style="cursor:pointer;" *ngFor="let x of soccerData" (click)="matchDetail(x.eventId);">
                                        <td>
                                           <div class="game-name"><a class="text-dark">
                                            {{x?.eventName}} / {{moment(x?.openDate).utcOffset("+05:30").format('MMM D YYYY h:mm:ss A')}}
                                              </a>
                                           </div>
                                           <div *ngIf="currentDate > x.openDate" class="game-icons">
                                              <span class="game-icon"><span class="active"></span></span> 
                                           </div>
                                        </td>
                                        <!----> 
                                        <td><button class="back"><span class="odd">{{x?.marketBook?.runners[0]?.availableToBack?.price}}</span></button></td>
                                        <!----><!----> 
                                        <td><button class="lay"><span class="odd">{{x?.marketBook?.runners[1]?.availableToLay?.price}}</span></button></td>
                                        <td><button class="back"><span class="odd">-</span></button></td>
                                        <td><button class="lay"><span class="odd">-</span></button></td>
                                        <td><button class="back"><span class="odd">{{x?.marketBook?.runners[1]?.availableToBack?.price}}</span></button></td>
                                        <!----><!----> 
                                        <td><button class="lay"><span class="odd">{{x?.marketBook?.runners[1]?.availableToLay?.price}}</span></button></td>
                                     </tr>
  
                                    </ng-container>
                                      </ng-container>
                                    
                                   <!-- Tennis -->
                                   <ng-container *ngIf="type==='tennis'">
                                    <ng-container *ngIf="tennisData?.length">
  
                                 <tr style="cursor:pointer;" *ngFor="let x of tennisData" (click)="matchDetail(x.eventId);">
                                    <td>
                                       <div class="game-name"><a class="text-dark">
                                        {{x?.eventName}} / {{moment(x?.openDate).utcOffset("+05:30").format('MMM D YYYY h:mm:ss A')}}
                                          </a>
                                       </div>
                                       <div *ngIf="currentDate > x.openDate" class="game-icons">
                                          <span class="game-icon"><span class="active"></span></span> 
                                       </div>
                                    </td>
                                    <!----> 
                                    <td><button class="back"><span class="odd">{{x?.marketBook?.runners[0]?.availableToBack?.price}}</span></button></td>
                                    <!----><!----> 
                                    <td><button class="lay"><span class="odd">{{x?.marketBook?.runners[1]?.availableToLay?.price}}</span></button></td>
                                    <td><button class="back"><span class="odd">-</span></button></td>
                                    <td><button class="lay"><span class="odd">-</span></button></td>
                                    <td><button class="back"><span class="odd">{{x?.marketBook?.runners[1]?.availableToBack?.price}}</span></button></td>
                                    <!----><!----> 
                                    <td><button class="lay"><span class="odd">{{x?.marketBook?.runners[1]?.availableToLay?.price}}</span></button></td>
                                 </tr>
  
                                </ng-container>
                                   </ng-container>
  
                                   <!-- cricket -->
                               <ng-container *ngIf="type==='cricket'" >
                                <!-- virtual -->
                                <ng-container *ngIf="virtualCricketData?.length">
  
                                  <tr style="cursor:pointer;" *ngFor="let x of virtualCricketData" (click)="virtualDetial(x.eventId);">
                                     <td>
                                        <div class="game-name"><a class="text-dark">
                                         {{x?.eventName}} / {{moment(x?.openDate).utcOffset("+05:30").format('MMM D YYYY h:mm:ss A')}}
                                           </a>
                                        </div>
                                        <div class="game-icons">
                                           <span *ngIf="currentDate > x.openDate" class="game-icon"><span class="active"></span></span> 
                                           <span class="game-icon">
                                              <!---->
                                           </span>
                                           <span class="game-icon">V</span>
                                        </div>
                                     </td>
                                     <!----> 
                                     <td><button class="back"><span class="odd">{{x?.marketBook?.runners[0]?.availableToBack?.price}}</span></button></td>
                                     <!----><!----> 
                                     <td><button class="lay"><span class="odd">{{x?.marketBook?.runners[1]?.availableToLay?.price}}</span></button></td>
                                     <td><button class="back"><span class="odd">-</span></button></td>
                                     <td><button class="lay"><span class="odd">-</span></button></td>
                                     <td><button class="back"><span class="odd">{{x?.marketBook?.runners[1]?.availableToBack?.price}}</span></button></td>
                                     <!----><!----> 
                                     <td><button class="lay"><span class="odd">{{x?.marketBook?.runners[1]?.availableToLay?.price}}</span></button></td>
                                  </tr>
       
                                 </ng-container>
  
                                <!-- cricket -->
                                <ng-container *ngIf="cricketData?.length">
  
                             <tr style="cursor:pointer;" *ngFor="let x of cricketData" (click)="matchDetail(x.eventId);">
                                <td>
                                   <div class="game-name"><a class="text-dark">
                                    {{x?.eventName}} / {{moment(x?.openDate).utcOffset("+05:30").format('MMM D YYYY h:mm:ss A')}}
                                      </a>
                                   </div>
                                   <div class="game-icons">
                                      <span *ngIf="currentDate > x.openDate" class="game-icon"><span class="active"></span></span> 
                                      <span class="game-icon">
                                         <!---->
                                      </span>
                                      <span class="game-icon"><img src="https://dzm0kbaskt4pv.cloudfront.net/v11/static/front/img/icons/ic_fancy.png" class="fancy-icon"></span> <span class="game-icon"><img src="https://dzm0kbaskt4pv.cloudfront.net/v11/static/front/img/icons/ic_bm.png" class="bookmaker-icon"></span>
                                   </div>
                                </td>
                                <!----> 
                                <td><button class="back"><span class="odd">{{x?.marketBook?.runners[0]?.availableToBack?.price}}</span></button></td>
                                <!----><!----> 
                                <td><button class="lay"><span class="odd">{{x?.marketBook?.runners[1]?.availableToLay?.price}}</span></button></td>
                                <td><button class="back"><span class="odd">-</span></button></td>
                                <td><button class="lay"><span class="odd">-</span></button></td>
                                <td><button class="back"><span class="odd">{{x?.marketBook?.runners[1]?.availableToBack?.price}}</span></button></td>
                                <!----><!----> 
                                <td><button class="lay"><span class="odd">{{x?.marketBook?.runners[1]?.availableToLay?.price}}</span></button></td>
                             </tr>
  
                            </ng-container>
                          </ng-container>


                            <!-- greyhound and horse -->
                            <ng-container *ngIf="type==='greyhoundrace' || type==='horserace'" >
                             
                              <!-- cricket -->
                              <ng-container >

                              
                                       <div class="nav nav-pills" role="tablist">
                                          <div class="nav-item" *ngFor="let m of countryList"><a role="tab" data-rr-ui-event-key="0" id="horse-tabs-tab-0" (click)="otherMarkets(eventTypeId,m)" aria-controls="horse-tabs-tabpane-0" aria-selected="true" class="nav-link" [ngClass]="(activeTab==m)?'active':''" tabindex="0" href="javascript:void(0)">{{m}}</a></div>
                                       </div>
                                       <div class="tab-content">
                                          <div role="tabpanel" id="horse-tabs-tabpane-0" aria-labelledby="horse-tabs-tab-0" class="fade tab-pane active show">
                                             <div class="bet-table position-relative horse-table">
                                                <div class="bet-table-body">
                                                   <div class="bet-table-body">
                                                      <div class="bet-table-row" *ngFor="let mb of dataList">
                                                         <div class="bet-nation-name">
                                                            <div class="game-icon"><i class="fas fa-tv icon-tv"></i></div>
                                                            <div class="bet-nation-game-name"><span>{{mb.eventName}}</span></div>
                                                         </div>
                                                         <div class="horse-time-detail">
                                                           <a  *ngFor="let m of mb.childs" (click)="matchDetail(m.eventId,m.eventTypeId)" >
                                                             <span class="">
         
                                                                {{
                                                                 moment(m?.startTime)
                                                                 .utcOffset("+05:30")
                                                                 .format("h:mm:ss A")
                                                                 }}
                                                             </span>
                                                           </a>
                                                               </div>
                                                      </div>
                                                     
                                                   </div>
                                                   <div class="bet-table-body" *ngIf="countryList?.length==0"><div class="bet-table-row">No Record Found</div></div>
                                    
                                                </div>
                                             </div>
                                          </div>
                                         
                                       </div>
                                   

                          </ng-container>
                        </ng-container>
            
                                  </tbody>
  
                               </table>
  
                               <ng-container *ngIf="(soccerData?.length==0 && type==='football') ||(tennisData?.length==0 && type==='tennis') || (cricketData?.length==0 && virtualCricketData?.length==0 && type==='cricket')">
                                <div style="background-color:#CCCCCC;" class="text-dark text-center p-2">No real-time records found</div>
                              </ng-container>
                              
                            </div>
                         </div>
                      </div>
                   </div>
                </div>
  
              
             </div>
          </div>
       </div>
    </div>

      <!-- deshboard_footer -->
       <app-desk-footer></app-desk-footer>
  </div>