import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { user_socket } from '../app.module';
import { Location } from '@angular/common';
import { ToastrService } from 'ngx-toastr';
import { environment } from 'src/environments/environment';
import { UsersService } from '../services/users.service';
import { Router,ActivatedRoute } from "@angular/router";

const casino_operatorId = environment['casino_operatorId'];

@Component({
  selector: 'app-casino-url',
  templateUrl: './casino-url.component.html',
  styleUrls: ['./casino-url.component.scss']
})
export class CasinoUrlComponent implements OnInit, AfterViewInit, OnDestroy {
  gameId: any;
  loader: boolean = false;
  userDetails: any;
  iframUrl: any;
  amountId: any;
  depWith: any;
  casinoBal: any = 0;
  walletBalance: any = 0;
  amount: string = '';
  casino: any;
  deviceInfo: any;
  allBetData: any;
  allBetDataLength: any = 0;
  gameCode:any;
  provideCode:any
  constructor(public route_v: ActivatedRoute,private socket: user_socket, public sanitizer: DomSanitizer, public httpClient: UsersService, private toastr: ToastrService, public router: Router, public _location: Location) {
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    this.userDetails = JSON.parse(sessionStorage.getItem('userDetails'));
    this.casino = JSON.parse(sessionStorage.getItem('casinoDb'));
    // checkDevice
    this.deviceInfo = JSON.parse(sessionStorage.getItem('is_desktop'));
    this.gameCode = this.route_v.snapshot.paramMap.get('gameCode'); 
    this.provideCode = this.route_v.snapshot.paramMap.get('provideCode'); 
    if (this.deviceInfo) {
     // this.getMyBets();
    }
    this.getUrl();
  }

  ngOnInit(): void {
    const data = {
      user: {
        _id: this.userDetails._id,
        key: this.userDetails.key,
        token: this.userDetails.verifytoken,
        details: {
          username: this.userDetails.details.username,
          role: this.userDetails.details.role,
          status: this.userDetails.details.status,
        },
      }
    };
    this.socket.emit('refresh-balance', data);
  }

  async getDetials() {
    try {
      const data = await JSON.parse(sessionStorage.getItem('userDetails'));
      return data;
    } catch (e) {
      return null;
    }

  }

  ngAfterViewInit() {
    // No Fun
  }

  getQtechType() {
    // const payload = { siteurl: 'diamond222.com' };
    const payload = { siteurl: window.location.hostname, userId: this.userDetails._id };
    this.httpClient.rmTokenPost("getSetting", payload).subscribe((res: any) => {
      if (res.success) {
        if (res.data.istenpaisa) {
          this.showModal();
        }

      } else {
        this.toastr.error(res.message, "Error!");
      }
    });
  }


  getUrl() {
   // this.getUserBalance();
    const userdata = {
      "gameData": {
        "gameCode":  this.gameCode,
        "providerCode": this.provideCode
    },
    device:'desktop'
    };
    // console.warn(userdata);

    this.httpClient.Post('casino/auth', userdata).subscribe((res: any) => {

      if (res.status) {
        let url = res.url;
        this.iframUrl = this.sanitizer.bypassSecurityTrustResourceUrl(url);
      }
      else {
        this.toastr.error(res.message, '', {
          timeOut: 10000,
        });
      }
    });

  }

  getAuraCasino() {
    // console.warn(this.casino.tableId);

    if (this.deviceInfo && this.casino.tableId) {
      this.iframUrl = this.sanitizer.bypassSecurityTrustResourceUrl('https://d2.fawk.app/#/splash-screen/' + this.userDetails.verifytoken + '/' + casino_operatorId
        + '?opentable=' + this.casino.tableId);
    }
    else if (this.casino.tableId === '' || this.casino.tableId === 'undefined' || !this.casino.tableId) {
      this.iframUrl = this.sanitizer.bypassSecurityTrustResourceUrl('https://m2.fawk.app/#/splash-screen/' + this.userDetails.verifytoken + '/' + casino_operatorId);
    }
    else {
      this.iframUrl = this.sanitizer.bypassSecurityTrustResourceUrl('https://m2.fawk.app/#/splash-screen/' + this.userDetails.verifytoken + '/' + casino_operatorId + '?opentable=' + this.casino.tableId);
    }

  }


  getUserBalance() {

    this.httpClient.Post("getUserDetails", null).subscribe((res: any) => {

      if (res.success) {
        this.walletBalance = res.doc.balance;
        //this.getCasinoBal();
      }
      else {
        console.warn(res.message);
      }
    });


  }

  getCasinoBal() {
    const userdata = {
      user: {
        _id: this.userDetails._id,
        key: this.userDetails.key,
        details: {
          username: this.userDetails.details.username,
          role: this.userDetails.details.role,
          status: this.userDetails.details.status,
        },
      }
    };
    this.socket.emit('get-userbalance', userdata);

    this.socket.on('get-balance-success', (function (data: any) {

      if (data) {
        this.casinoBal = data.amount;
      }
    }).bind(this));

  }

  action(id, value) {
    this.amountId = id;
    this.depWith = value;
  }

  onSubmit() {
    if (this.amount == "") {
      this.toastr.error('amount is invalid', 'Error !');
    }
    else {
      this.loader = true;
      if (this.amountId == '1') {
        this.depositApi(this.amount);
      }
      else if (this.amountId == '2') {
        this.withdrawApi(this.amount);
      }

    }
  }

  withdrawApi(amount: string) {
    this.httpClient.Get('casino-balance-withdrawapp/' + this.userDetails.details._id + '/' + amount).subscribe((response: any) => {

      if (response.error) {
        this.toastr.error(response.message, 'Error!');
        this.loader = false;
      }
      else {
        this.toastr.success(response.message, 'Success!');
        this.loader = false;
        this.refreshUsrBal();
       // this.getCasinoBal();
      }
    });

  }

  depositApi(amount: string) {
    this.httpClient.Get('casino-balance-transferapp/' + this.userDetails.details._id + '/' + amount).subscribe((response: any) => {

      if (response.error) {
        this.toastr.error(response.message, 'Error!');
        this.loader = false;
      }
      else {
        this.toastr.success(response.message, 'Success!');
        this.loader = false;
        this.refreshUsrBal();
        //this.getCasinoBal();
      }
    });

  }

  refreshUsrBal() {

    this.httpClient.Post("getUserDetails", null).subscribe((res: any) => {

      if (res.success) {
        this.walletBalance = res.doc.balance;
        this.httpClient.updateUserBalanceSubject(res.doc);
      }
      else {
        console.warn(res.message);
      }
    });


  }

  getMyBets() {
    this.allBetData = [];
    const getBet = {
      token: this.userDetails.verifytoken,
      filter: {
        eventId: this.casino.tableId,
        username: this.userDetails.details.username,
        deleted: false,
        result: 'ACTIVE',
      },
      sort: { placedTime: -1 },
    };

    this.socket.emit('get-bets', getBet);

    this.socket.on('get-bets-success', (function (data: any) {

      if (data.length > 0) {
        if (data[0].eventId === this.casino.tableId) {
          this.allBetDataLength = 0;
          this.allBetData = data;
          this.allBetDataLength = this.allBetData.length;

        }

        //  this.socket.removeAllListeners('get-bets-success');
      }

    }).bind(this));

  }

  back() {
    this._location.back();
  }

  // Show modal function 
  showModal() {
    // Get modal and buttons 
    const modal = document.getElementById('modal');
    modal.style.display = 'flex';
  }

  // Hide modal function 
  hideModal() {
    const modal = document.getElementById('modal');
    modal.style.display = 'none';
  }

  ngOnDestroy() {
    // sessionStorage.removeItem('casinoDb');
    this.socket.removeAllListeners();
  }

}
